import { useRef, memo, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { motion } from 'framer-motion';

const servicesOptions = [
  { value: 'Social Media Management', label: 'Social Media Management' },
  { value: 'Videography', label: 'Videography' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Website Development', label: 'Website Development' },
  { value: 'Branding', label: 'Branding' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'All Of The Above', label: 'All Of The Above' },
];

const servicesStyle = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'black' : base.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #6b7280' : 'none',
    outline: 'none',
    borderRadius: '6px',
    padding: '2px 4px',
    '&:hover': {
      borderColor: '#6b7280',
      boxShadow: '0 0 0 1px #6b7280',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#42424570',
    fontWeight: '600',
  }),
  input: (base) => ({
    ...base,
    color: '#42424570',
    fontWeight: '600',
  }),
  menu: (base) => ({
    ...base,
    zIndex: 5,
    padding: '2px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'black' : base.backgroundColor,
    color: state.isFocused ? 'white' : base.color,
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      outline: 'none',
      boxShadow: 'none',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'black',
    color: 'black',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'white',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  }),
};

const formAnimations = {
  fadeInUp: {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 2, ease: 'easeOut' },
    },
  },
  staggerContainer: {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.1 },
    },
  },
};

const InputField = memo(
  ({ label, id, type = 'text', placeholder, ...rest }) => (
    <motion.div
      className="max-sm:col-span-2"
      variants={formAnimations.fadeInUp}
      initial="hidden"
      animate="visible"
    >
      <label
        htmlFor={id}
        className="block text-sm font-semibold leading-6 text-white"
      >
        {label}
      </label>
      <div className="mt-2.5">
        <input
          id={id}
          name={id}
          type={type}
          placeholder={placeholder}
          className="max-sm:text-[14px] block w-full rounded-md border-0 px-3.5 py-2 hiw-text shadow-sm ring-1 ring-inset ring-zinc placeholder:text-gray-300 sm:text-sm sm:leading-6 max-sm:placeholder:text-[14px] focus:outline-none focus:ring-2 focus:ring-gray-500"
          {...rest}
        />
      </div>
    </motion.div>
  )
);
InputField.displayName = 'InputField';

const EmailForm = () => {
  const animatedComponent = makeAnimated();
  const form = useRef();
  const [selectedServices, setSelectedServices] = useState([]);

  const successToast = () => {
    toast.success('Success! Your email has been delivered.', {
      position: 'top-right',
    });
  };

  const errorToast = () => {
    toast.error(
      'Uh oh! There was a problem sending your email. Please try again.',
      { position: 'top-right' }
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_qjag8bk', 'template_7mblhs8', form.current, {
        publicKey: 'VCS_iSGiZTXooSReV',
      })
      .then(
        () => {
          successToast();
          form.current.reset();
          setSelectedServices([]);
        },
        () => {
          errorToast();
        }
      );
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <Toaster />
      <motion.form
        ref={form}
        onSubmit={sendEmail}
        className="mx-auto mt-12 max-w-xl"
        variants={formAnimations.fadeInUp}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="grid grid-cols-2 gap-x-4 gap-y-6 max-sm:gap-y-2"
          variants={formAnimations.staggerContainer}
          initial="hidden"
          animate="visible"
        >
          <InputField
            id="user_name"
            label="Name"
            placeholder="Your Name"
            autoFocus
            required
          />

          <InputField
            id="business_name"
            label="Business Name"
            placeholder="Perseus Studio"
          />

          <InputField
            id="website_name"
            label="Website"
            placeholder="www.perseustudio.com"
          />

          <InputField
            id="instagram_id"
            label="Instagram"
            placeholder="@Perseustudio"
          />

          <motion.div
            className="col-span-2"
            variants={formAnimations.fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <label
              htmlFor="user_service"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Choose Your Desired Services
            </label>
            <div className="relative mt-2.5 text-zinc text-sm">
              <Select
                options={servicesOptions}
                closeMenuOnSelect={false}
                isMulti
                components={animatedComponent}
                styles={servicesStyle}
                value={selectedServices}
                onChange={(selected) => {
                  setSelectedServices(selected || []);
                }}
              />
              {/* Hidden input to pass selected services to EmailJS */}
              <input
                id="user_service"
                name="user_service"
                type="hidden"
                value={selectedServices
                  .map((option) => option.value)
                  .join(', ')}
              />
            </div>
          </motion.div>

          <div
            className="col-span-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}
          >
            <InputField
              id="user_email"
              label="Email"
              type="email"
              placeholder="info@perseustudio.com"
              required
            />
          </div>

          <div className="col-span-2">
            <label
              htmlFor="user_phoneNumber"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Phone Number
            </label>
            <motion.div
              className="relative mt-2.5"
              variants={formAnimations.fadeInUp}
            >
              <select
                id="user_country"
                name="user_country"
                className="absolute inset-y-0 left-0 h-full rounded-md border-0 bg-transparent py-0 px-1 text-[14px] text-zinc font-bold"
              >
                <option>CA 🇨🇦</option>
                <option>US 🇺🇸</option>
                <option>EU 🇪🇺</option>
                <option>UAE 🇦🇪</option>
              </select>

              <input
                id="user_phoneNumber"
                name="user_phoneNumber"
                placeholder="+1 (778) 887-8363"
                type="number"
                autoComplete="tel"
                className="max-sm:text-[14px] block w-full rounded-md border-0 px-3.5 py-2 pl-20 hiw-text shadow-sm ring-1 ring-inset ring-zinc placeholder:text-gray-300 sm:text-sm sm:leading-6 placeholder:pl-4 max-sm:placeholder:text-[14px]"
              />
            </motion.div>
          </div>

          <motion.div className="col-span-2" variants={formAnimations.fadeInUp}>
            <label
              htmlFor="user_message"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Tell us about you
            </label>
            <div className="mt-2.5">
              <textarea
                id="user_message"
                name="user_message"
                placeholder="Short description about your business and your goals ..."
                rows={4}
                required
                className="max-sm:text-[14px] block w-full rounded-md border-0 px-3.5 py-2 hiw-text shadow-sm ring-1 ring-inset ring-zinc placeholder:text-gray-300 sm:text-sm sm:leading-6 max-sm:placeholder:text-[14px]"
              />
            </div>
          </motion.div>
        </motion.div>

        <motion.button
          type="submit"
          className="mt-8 mb-12 btn block w-full text-center cursor-pointer"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: 'easeOut' }}
        >
          Submit
        </motion.button>
      </motion.form>
    </motion.section>
  );
};

export default EmailForm;
